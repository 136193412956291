require("./backtotop.scss");

(function (targetSelector) {
    if (!targetSelector) return;

    var backToTopBtn = $(".back-to-top-btn");
    if (!backToTopBtn) return;

    var enabled = null;
    var testing = Boolean(window.testingBackToTop);

    function isTargetScrolledOut(elem) {
        var windowTop = $(window).scrollTop();

        var offset = elem.offset();
        if (!offset) return false;
        var elemTop = offset.top;
        var elemBottom = elemTop + elem.height();

        return elemBottom < windowTop;
    }

    $(function () {
        $(window).on("scroll", function () {
            if (!window.optimizelyUtil) return;

            if (!isTargetScrolledOut($(targetSelector))) {
                backToTopBtn.removeClass("visible");
            } else {
                if (!testing) {
                    backToTopBtn.addClass("visible");
                    return;
                }

                if (enabled === null)
                    enabled = window.optimizelyUtil.isFeatureEnabled("cars-desktop-homepage-back-to-top");
                if (enabled)
                    backToTopBtn.addClass("visible");
            }
        });

        backToTopBtn.click(function () {
            $(document.documentElement).animate({ scrollTop: 0 }, "fast");
            if (window.optimizelyUtil) {
                window.optimizelyUtil.track("click-backtotop-homepage");
            }
        });
    });
})(window.backToTopTarget);
